import Axios from 'axios'
import getDomainURL from 'utils/api'
import FileSaver from 'file-saver'
import { clearItem, getItem } from 'utils/tokenStore'
import { toast } from 'react-toastify'

import { processSite } from 'utils/host'
import { getCipherHeader } from 'utils/cipher-text'

export const Get = (url, response, error, load, isPublic ) => {
  load(true)
  let token = localStorage['IMPERSONATE_TOKEN'] || localStorage['TECHBASE_TOKEN']
  Axios.defaults.headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Authorization': isPublic ? getCipherHeader() : token,
    'Site': processSite( window.location.host )
  }
  const requestUrl = url.indexOf( 'http' ) > -1 ? url : `${ getDomainURL()}${ url }`
  return Axios.get( requestUrl ).then(res => {
    response(res.data)
    load(false)
  }).catch(err => {
    console.error( err )
    if (err && err.response) {
      if (err.response.status === 401) {
        if( localStorage['IMPERSONATE_TOKEN'] ){
          toast.error( 'Impersonate session has expired' )
          clearItem( 'IMPERSONATE_TOKEN' )
          window.location.href = '#/dashboard/member-list'
          window.location.reload(true)
        } else {
          if( getItem( 'TECHBASE_TOKEN' ) ){
            toast.error( 'Due to inactivity, your session has expired, please login again.' )
            clearItem( 'TECHBASE_TOKEN' )
          }
        }
      } else if (err.response.status === 500) {
        error('Server encountered issues. Please contact your system admin for assistance.')
      } else {
        error(err.response.data )
      }
    } else if (err.response) {
      error(err.response.data)
    } else {
      error('You are disconnnected from the internet, please reconnect to use the system. If problem persists, please contact the system admin.')
    }
    load(false)
  })
}

export const Post = (url, data, response, error, load, isPublic) => {
  load(true)
  let token = localStorage['IMPERSONATE_TOKEN'] || localStorage[ 'TECHBASE_TOKEN' ]
  Axios.defaults.headers = {
    'Access-Control-Allow-Origin': '*',
    'Authorization': isPublic ? getCipherHeader() : token,
    'Site': processSite( window.location.host )
  }
  const requestUrl = url.indexOf( 'http' ) > -1 ? url : `${ getDomainURL()}${ url }`
  return Axios.post( requestUrl, data ).then( res => {
    response (res.data )
    load( false )
  }).catch( err => {
    console.error( err )
    if ( err && err.response ) {
      if( err.response.status === 413 ){
        error('The file is too large. Please try with smaller file')
      } else if (err.response.status === 401) {
        if( localStorage['IMPERSONATE_TOKEN'] ){
          toast.error( 'Impersonate session has expired' )
          clearItem( 'IMPERSONATE_TOKEN' )
          window.location.href = '#/dashboard/member-list'
          window.location.reload(true)
        } else {
          if( getItem( 'TECHBASE_TOKEN' ) ){
            toast.error( 'Due to inactivity, your session has expired, please login again.' )
            clearItem( 'TECHBASE_TOKEN' )
          }
        }
      } else {
        error( err.response.data )
      }
    } else {
      error('You are disconnnected from the internet, please reconnect to use the system. If problem persists, please contact the system admin.')
    }
    load( false )
  })
}

export const Put = (url, data, response, error, load, isPublic, customToken ) => {
  load(true)
  let token = customToken ? customToken : ( localStorage['IMPERSONATE_TOKEN'] ||  localStorage['TECHBASE_TOKEN'] )
  Axios.defaults.headers = {
    'Access-Control-Allow-Origin': '*',
    'Authorization': isPublic ? getCipherHeader() : token,
    'Site': processSite( window.location.host )
  }
  return Axios.put(`${getDomainURL()}${url}`, data).then(res => {
    response(res.data)
    load(false)
  }).catch(err => {
    console.error( err )
    if (err && err.response && err.response.status) {
      if (err.response.status === 500) {
        error('Server encountered issues. Please contact your system admin for assistance.')
      } else if(err.response.status === 422){
        error( err.response.data )
      } else if( err.response.status === 413 ){
        error('The file is too large. Please try with smaller file')
      } else if (err.response.status === 401) {
        if( localStorage['IMPERSONATE_TOKEN'] ){
          toast.error( 'Impersonate session has expired' )
          clearItem( 'IMPERSONATE_TOKEN' )
          window.location.href = '#/dashboard/member-list'
          window.location.reload(true)
        } else {
          if( getItem( 'TECHBASE_TOKEN' ) ){
            toast.error( 'Due to inactivity, your session has expired, please login again.' )
            clearItem( 'TECHBASE_TOKEN' )
          }
        }
      } else {
        error( err.response.data )
      }
    } else if (err) {
      error(err.response.data)
    } else {
      error('You are disconnnected from the internet, please reconnect to use the system. If problem persists, please contact the system admin.')
    }
    load(false)
  })
}

export const Delete = (url, response, error, load, isPublic) => {
  load(true)
  let token = localStorage['IMPERSONATE_TOKEN'] || localStorage['TECHBASE_TOKEN']
  Axios.defaults.headers = {
    'Access-Control-Allow-Origin': '*',
    'Authorization': isPublic ? getCipherHeader() : token,
    'Site': processSite( window.location.host )
  }
  return Axios.delete(`${getDomainURL()}${url}`).then(res => {
    response(res.data)
    load(false)
  }).catch(err => {
    console.error( err )
    if (err && err.response && err.response.status) {
      if (err.response.status === 500) {
        error('Server encountered issues. Please contact your system admin for assistance.')
      } else if(err.response.status === 422){
        error( err.response.data )
      } else if (err.response.status === 401) {
        if( localStorage['IMPERSONATE_TOKEN'] ){
          toast.error( 'Impersonate session has expired' )
          clearItem( 'IMPERSONATE_TOKEN' )
          window.location.href = '#/dashboard/member-list'
          window.location.reload(true)
        } else {
          if( getItem( 'TECHBASE_TOKEN' ) ){
            toast.error( 'Due to inactivity, your session has expired, please login again.' )
            clearItem( 'TECHBASE_TOKEN' )
          }
        }
      } else {
        error(err.response.data)
      }
    } else if (err) {
      error(err.response.data[0])
    } else {
      error('You are disconnnected from the internet, please reconnect to use the system. If problem persists, please contact the system admin.')
    }
    load(false)
  })
}

export const DownloadFile = ( url, savedFilename, error, load ) => {
  load( true )
  let token = localStorage['IMPERSONATE_TOKEN'] || localStorage['TECHBASE_TOKEN']
  return Axios.get( `${getDomainURL()}${url}`, {
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': token,
      'Site': processSite( window.location.host )
    }
  }).then( res => {
    return res.data
  }).then( blobby => {
    FileSaver.saveAs( blobby, savedFilename )
    load( false )
  }).catch( err => {
    console.error( err )
    if (err.response.status === 401) {
      if( localStorage['IMPERSONATE_TOKEN'] ){
        toast.error( 'Impersonate session has expired' )
        clearItem( 'IMPERSONATE_TOKEN' )
        window.location.href = '#/dashboard/member-list'
        window.location.reload(true)
      } else {
        if( getItem( 'TECHBASE_TOKEN' ) ){
          toast.error( 'Due to inactivity, your session has expired, please login again.' )
          clearItem( 'TECHBASE_TOKEN' )
        }
      }
    } 
    error( err.message )
    load( false )
  })
}

export const PostDownloadFile = ( url, data, savedFilename, error, load ) => {
  load( true )
  let token = localStorage['TECHBASE_TOKEN']
  return Axios.post( `${getDomainURL()}${url}`, data, {
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': token,
      'Site': processSite( window.location.host )
    }
  }).then( res => {
    return res.data
  }).then( blobby => {
    FileSaver.saveAs( blobby, savedFilename )
    load( false )
  }).catch( err => {
    console.error( err )
    error( err.message )
    load( false )
  })
}

export const GetFileBlob = ( url, response, error, load ) => {
  load( true )
  let token = localStorage['IMPERSONATE_TOKEN'] || localStorage['TECHBASE_TOKEN']
  return Axios.get( `${getDomainURL()}${url}`, {
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': token,
      'Site': processSite( window.location.host )
    }
  }).then( res => {
    response(res.data)
    load( false )
  })
  .catch( err => {
    console.error( err )
    if (err.response.status === 401) {
      if( localStorage['IMPERSONATE_TOKEN'] ){
        toast.error( 'Impersonate session has expired' )
        clearItem( 'IMPERSONATE_TOKEN' )
        window.location.href = '#/dashboard/member-list'
        window.location.reload(true)
      } else {
        if( getItem( 'TECHBASE_TOKEN' ) ){
          toast.error( 'Due to inactivity, your session has expired, please login again.' )
          clearItem( 'TECHBASE_TOKEN' )
        }
      }
    } 
    error( err.message )
    load( false )
  })
}

export const OpenPDFAtNewTab = ( url, savedFilename, error, load ) => {
  load( true )
  let token = localStorage[ 'IMPERSONATE_TOKEN' ] || localStorage[ 'TECHBASE_TOKEN' ]
  return Axios.get( `${ getDomainURL()}${ url }`, {
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': token,
      'Site': processSite( window.location.host )
    }
  }).then( res => {
    return res.data
  }).then( blobby => {
    window.open( URL.createObjectURL( blobby ))
    load( false )
  }).catch( err => {
    console.error( err )
    if ( err.response.status === 401 ) {
      if( localStorage[ 'IMPERSONATE_TOKEN' ]){
        toast.error( 'Impersonate session has expired' )
        clearItem( 'IMPERSONATE_TOKEN' )
        window.location.href = '#/dashboard/member-list'
        window.location.reload( true )
      } else {
        if( getItem( 'TECHBASE_TOKEN' )){
          toast.error( 'Due to inactivity, your session has expired, please login again.' )
          clearItem( 'TECHBASE_TOKEN' )
        }
      }
    }
    error( err.message )
    load( false )
  })
}

export const OpenPDFAtNewTabWithFilter = ( url, data, savedFilename, error, load ) => {
  let token = localStorage['IMPERSONATE_TOKEN'] || localStorage[ 'TECHBASE_TOKEN' ]
  return Axios.post( `${ getDomainURL()}${ url }`, data, {
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': token,
      'Site': processSite( window.location.host )
    }
  }).then( res => {
    return res.data
  }).then( blobby => {
    window.open( URL.createObjectURL( blobby ))
    load( false )
  }).catch( err => {
    console.error( err )
    if ( err.response.status === 401 ) {
      if( localStorage[ 'IMPERSONATE_TOKEN' ]){
        toast.error( 'Impersonate session has expired' )
        clearItem( 'IMPERSONATE_TOKEN' )
        window.location.href = '#/dashboard/member-list'
        window.location.reload( true )
      } else {
        if( getItem( 'TECHBASE_TOKEN' )){
          toast.error( 'Due to inactivity, your session has expired, please login again.' )
          clearItem( 'TECHBASE_TOKEN' )
        }
      }
    }
    error( err.message )
    load( false )
  })
}
