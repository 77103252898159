import React, { useEffect } from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import { Switch, Route, withRouter, Redirect } from "react-router-dom"

import Login from "./containers/Login"
import Dashboard from "./containers/Dashboard"
import Registration from "./containers/Registration"
import ForgotPassword from "containers/ForgotPassword"
import ForgotSecurityPin from "containers/ForgotSecurityPin"
import { getItem } from "./utils/tokenStore"

import { getProfile } from 'actions/profile' 
import PaymentFailed from "containers/Registration/PaymentFailed"
import PaymentSuccessful from "containers/Registration/PaymentSuccessful"
import PaymentPending from "containers/Registration/PaymentPending"

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getItem( 'TECHBASE_TOKEN' ) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props },
          }}
        />
      )
    }
  />
)

const CustomRouter = ({ location, ...props }) => {
  useEffect(()=>{
    if( getItem( 'TECHBASE_TOKEN' ) ){
      props.getProfile( props.data.ClientReducer )
    }
  },[ props.data.ClientReducer.clientInfo ])

  if(!props.siteInfo.status){
    return (
      <div style={{width:"100%", backgroundColor: "#fff"}}><img style={{width: "100%", height: "100vh", objectFit: "contain"}} src="UnderMaintenance.jpg"/></div>
    )
  }
  return (
    <Switch>
      <Route exact path="/login" component={Login} initialPath />
      <Route exact path="/forgot-password" component={ForgotPassword} initialPath />
      <Route exact path="/forgot-security-pin" component={ForgotSecurityPin} initialPath />
      <Route exact path="/register/payment_successful/*" component={PaymentSuccessful}/>
      <Route exact path="/register/payment_pending/*" component={PaymentPending}/>
      <Route exact path="/register/payment_failed/*" component={PaymentFailed}/>
      <Route path="/register" component={Registration} />
      <PrivateRoute path="/dashboard" component={ Dashboard } />
      <Redirect from={"/"} to={"/login"} />
    </Switch>
  )
}

const mapStateToProps = (state) => ({ 
  data: state,
  siteInfo: state.ClientReducer.siteInfo,
})
export default compose(
  connect(mapStateToProps, { getProfile } ), 
  withRouter
)(CustomRouter)
